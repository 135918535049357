//Show below slide button
 $('.showBelowSlide').click( function() {
   var nextSection = $(this).closest('section').next('section');
   
   if(nextSection.is(':visible')) {

     nextSection.hide();

   } else {

     nextSection.show();

   }

 });

$(document).ready( function() {
  if ($(window).width() < 1025) {
     $('.parallax-window').attr('data-position-y', '0px');
  }
  else {
     $('.parallax-window').attr('data-position-y', '0px');
  }
});

$('.parallax-window').each( function() {
  var imageURL = $(this).attr('data-image');
  if(imageURL == in_array('how-we-work-colour-copy-copy-56602664cb2bb.JPG', 'about-us-56a616a7f1f59.jpg')) {
    $(this).attr('data-position-y', '0px');
  }
});
