$(document).ready(function() {

if ($(window).width() >= 1200){	

	var stickyTop = $('.headerContainer').offset().top;

	$(window).on( 'scroll', function(){
		if ($(window).scrollTop() >= stickyTop) {
		    $('.headerContainer').addClass('navbar-fixed-top');
        $('#logo').attr('src', '/images/layout/logo-fixed.PNG');
		    $('.content-container').css('margin-top', '160px');
        $('.logo').css('margin-top', '0px');
        $('.topHeaderContainer').hide();
		} else {
		    $('.headerContainer').removeClass('navbar-fixed-top');
        $('.logo').attr('src', '/images/layout/logo.png');
		    $('.content-container').css('margin-top', '0px');
        $('.logo').css('margin-top', '30px');
        $('.topHeaderContainer').show();
		}
	    });

} else {
  $('.topHeaderContainer').hide();
  var stickyTop = $('.headerContainer').offset().top;

	$(window).on( 'scroll', function(){
		if ($(window).scrollTop() >= stickyTop) {
		    $('.headerContainer').addClass('navbar-fixed-top');
		    $('.content-container').css('margin-top', '100px');
        $('.logo').css('margin-top', '0px');
		} else {
		    $('.headerContainer').removeClass('navbar-fixed-top');
        $('.logo').attr('src', '/images/layout/logo.png');
		    $('.content-container').css('margin-top', '0px');
		}
	    });
}



});

$('ul.nav li.dropdown').hover(function() {
  $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
}, function() {
  $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
});

