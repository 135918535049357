$(document).ready(function(){    
    $('.panel-button').click( function() {

      var panelId = '.panel-' + $(this).attr('data-id');
      var panelTitle = '.panel-title-' + $(this).attr('data-id');

      if($(panelId).hasClass("fa-plus")) {
        $('.fa').removeClass("fa-minus").addClass("fa-plus");
        $(panelId).removeClass("fa-plus").addClass("fa-minus");
      } else {
        $(panelId).removeClass("fa-minus").addClass("fa-plus");
      }

      if($(panelTitle).hasClass("panel-closed")) {
        $('.panel-button').removeClass("panel-open").addClass("panel-closed");
        $(panelTitle).removeClass("panel-closed").addClass("panel-open");
      } else {
        $(panelTitle).removeClass("panel-open").addClass("panel-closed");
      }

    }); 

});
