  // // PUT SCROLL SPEED ONTO FOOTER
  //     $(window).load( function() {
  //       var footerSpeed = $('.parallax-section').last().data('scroll-speed');
  //       $('.footer').data("data-scroll-speed",footerSpeed);
  //      
  //       var originalDocHeight = $(document).height();
  //       var windowHeight = $(window).height();
  //       var sectionsHeight = 0;
  //       var overallSpeed = 0;
  //       var aboveZero = 0;
  //       var positionTop = $('.topHeaderContainer').outerHeight() + $('.headerContainer').outerHeight();
  //       var amountSections = 0;
  //
  //       $('section[data-scroll-speed]').each( function() {
  //         if($(this).is(':visible')) {
  //           var height = $(this).height();
  //           sectionsHeight = height + sectionsHeight;
  //           var speed = $(this).attr('data-scroll-speed');
  //           overallSpeed =  parseFloat(speed) + parseFloat(overallSpeed);
  //           if(speed > 0) {
  //             aboveZero = aboveZero + 1;
  //           }
  //
  //           positionTop = height + positionTop;
  //           if($(this).next('.box').is(':visible')) {
  //             $(this).next('.box').css('top', positionTop);
  //           } else {
  //             $(this).next('.box').css('top', positionTop);
  //             $(this).next('.box').next('.box').css('top', positionTop);
  //           }
  //         }
  //
  //         amountSections = amountSections + 1;
  //
  //       });
  //
  //           var amount = ((overallSpeed * amountSections) / amountSections) + overallSpeed;
  //           //var newDocHeight = sectionsHeight / amount;
  //           console.log('sectionsHeight', sectionsHeight);
  //           console.log('windowHeight', windowHeight);
  //           console.log('footer', $('.footer').height());
  //           var newDocHeight = (sectionsHeight / overallSpeed) + ($('.footer').height() + $('.headerContainer').height());
  //           console.log('newDocHeight', newDocHeight);
  //           $('.parallax-white-space').css('height', newDocHeight + 'px');
  //
  //       // Set page scroll speed and smoothness
  //       jQuery.scrollSpeed(100, 1400);
  //
  //
  //       // SHOW ON SCROLL
  //       $('.showOnScroll').each( function() {
  //         $(this).css('top', ($(this).css('top').replace(/[^-\d\.]/g, '') - $(this).outerHeight()) + 'px')
  //       });
  //
  //     });
  //
  //     // PARALLAX!
  //     $(window).scroll( function() {
  //         $('section[data-scroll-speed]').each( function() {
  //           var speed = $(this).attr('data-scroll-speed');
  //           var scrolled = $(window).scrollTop();
  //           var position = scrolled * speed + 'px';
  //           $(this).css('transform', 'translateY(-'+position+')');
  //         })
  //     })
